<template>
  <div>
    <b-row>
      <b-col
        md="12"
        lg="5"
      >
        <b-card title="Cadastre um novo cliente aqui 🚀">
          <validation-observer ref="registerForm">
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="createClient"
            >
              <!-- name -->
              <b-form-group
                label="Nome"
                label-for="name"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nome"
                  rules="required"
                >
                  <b-form-input
                    id="name"
                    v-model="name"
                    :state="errors.length > 0 ? false:null"
                    name="register-username"
                    placeholder="Felipe"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- username -->
              <b-form-group
                label="Usuario"
                label-for="username"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Usuario"
                  rules="required"
                >
                  <b-form-input
                    id="username"
                    v-model="username"
                    :state="errors.length > 0 ? false:null"
                    name="register-username"
                    placeholder="fefedomangue"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- phone -->
              <b-form-group
                label="Celular"
                label-for="phone"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Celular"
                  rules="required"
                >
                  <b-form-input
                    id="phone"
                    v-model="phone"
                    :state="errors.length > 0 ? false:null"
                    name="register-phone"
                    placeholder="(21) 93274-2910"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label="Senha"
                label-for="password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Senha"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="password"
                      v-model="password"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      class="form-control-merge"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit button -->
              <b-button
                variant="primary"
                block
                type="submit"
              >
                CADASTRAR
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>

      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BFormInput, BForm, BButton, BRow, BCol,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import funcs from '@/services/func.service'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

const user = JSON.parse(localStorage.getItem('admin'))

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCard,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      name: '',
      username: '',
      password: '',
      phone: '',
      status: '',
    }
  },
  methods: {

    createClient() {
      const body = {
        name: this.name, username: this.username, password: this.password, phone: this.phone, reseller_id: user.id,
      }
      funcs.createClient(body).then(success => {
        console.log(success)
        if (success.data.token) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sucesso',
              icon: 'CheckIcon',
              text: 'Usuario criado com sucesso!!!',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
        } else if (success.code === 'ER_DUP_ENTRY') {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Já existe cadastro desse grupo',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro',
              icon: 'XIcon',
              text: 'Verifique os campos digitados!',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
