/* eslint-disable class-methods-use-this */
import axios from 'axios'
import authHeader from './auth-header'
import authadminHeader from './authadmin-header'

const API_URL = 'https://wppbot-api-xx36i.ondigitalocean.app/'
// const API_URL = 'http://127.0.0.1:3000/'

class UserService {
  // getPublicContent() {
  //   return axios.get(`${API_URL}all`)
  // }

  // CLIENTES
  fetchResellers() {
    return axios.get(`${API_URL}func/getresellers`)
  }

  fetchUser(id) {
    return axios.get(`${API_URL}func/getuser/${id}`, { headers: authHeader() })
  }

  fetchBotReseller(id) {
    return axios.get(`${API_URL}func/getbotreseller/${id}`, { headers: authHeader() })
  }

  fetchUserGroups(id) {
    return axios.get(`${API_URL}func/getusergroups/${id}`, { headers: authHeader() })
  }

  fetchUserOrders(id) {
    return axios.get(`${API_URL}func/getuserorders/${id}`, { headers: authHeader() })
  }

  fetchUserPaymentHistories(id) {
    return axios.get(`${API_URL}func/getuserpaymenthistories/${id}`, { headers: authHeader() })
  }

  fetchMissions() {
    return axios.get(`${API_URL}func/getmissions`, { headers: authHeader() })
  }

  fetchMissionHistory(id) {
    return axios.get(`${API_URL}func/getmissionhistory/${id}`, { headers: authHeader() })
  }

  fetchPointsHistory(id) {
    return axios.get(`${API_URL}func/getpointshistory/${id}`, { headers: authHeader() })
  }

  fetchVerifyPointsHistory() {
    return axios.get(`${API_URL}func/getverifypointshistory`, { headers: authHeader() })
  }

  fetchPoints(id) {
    return axios.get(`${API_URL}func/getpoints/${id}`, { headers: authHeader() })
  }

  fetchRanking(id) {
    return axios.get(`${API_URL}func/getranking/${id}`, { headers: authHeader() })
  }

  atualizaFuncao(id, idgrupo, funcao, texto, image, timer) {
    return axios.post(`${API_URL}func/atualizafuncao/${id}/${idgrupo}/${funcao}`, { texto, image, timer }, { headers: authHeader() })
  }

  getTexto(idgrupo, funcao) {
    return axios.get(`${API_URL}func/gettexto/${idgrupo}/${funcao}`, { headers: authHeader() })
  }

  // REVENDA
  fetchLastGroups() {
    return axios.get(`${API_URL}func/getlastgroups`, { headers: authadminHeader() })
  }

  updateGroup(status) {
    return axios.post(`${API_URL}func/updategroup`, status, { headers: authadminHeader() })
  }

  updatePointsHistories(status) {
    return axios.post(`${API_URL}func/updatepointshistories`, status, { headers: authadminHeader() })
  }

  createBot(body) {
    return axios.post(`${API_URL}func/createbot`, body, { headers: authadminHeader() })
  }

  createClient(body) {
    return axios.post(`${API_URL}user/create`, body, { headers: authadminHeader() })
  }

  listAllClients() {
    return axios.get(`${API_URL}func/listallclients`, { headers: authadminHeader() })
  }

  listAllGroups(reference) {
    return axios.post(`${API_URL}func/listallgroups`, reference, { headers: authadminHeader() })
  }

  listAllPointsHistory() {
    return axios.get(`${API_URL}func/listallpointspistory`, { headers: authadminHeader() })
  }

  // COUNTS
  countLiberationPoints(id) {
    return axios.get(`${API_URL}func/countliberationpoints/${id}`, { headers: authHeader() })
  }

  countSalles() {
    return axios.get(`${API_URL}func/countsalles`, { headers: authadminHeader() })
  }

  countGroupsApproved() {
    return axios.get(`${API_URL}func/countgroupapproved`, { headers: authadminHeader() })
  }

  countGroupsExpired() {
    return axios.get(`${API_URL}func/countgroupexpired`, { headers: authadminHeader() })
  }

  // CREATE

  creategroup(id, payload) {
    return axios.post(`${API_URL}func/creategroup/${id}`, payload, { headers: authHeader() })
  }

  creategroupadmin(id, payload) {
    return axios.post(`${API_URL}func/creategroupadmin/${id}`, payload, { headers: authadminHeader() })
  }

  createOrderPayment(id, payload) {
    return axios.post(`${API_URL}func/createorderpayment/${id}`, payload, { headers: authHeader() })
  }

  createPointsHistories(id, payload) {
    return axios.post(`${API_URL}func/createpointshistories/${id}`, payload, { headers: authHeader() })
  }

  createMission(id, payload) {
    return axios.post(`${API_URL}func/createmission/${id}`, payload, { headers: authadminHeader() })
  }

  // PAYMENT
  getLinkPayment(token) {
    return axios.post(`${API_URL}pay/mercadopago`, token, { headers: authHeader() })
  }

  consultaPayment(paymentid, token) {
    return axios.get(`https://api.mercadopago.com/v1/payments/${paymentid}`, { headers: { Authorization: `Bearer ${token}` } })
  }

  updatePayment(status, groupid) {
    return axios.post(`${API_URL}func/updatepayment/${groupid}`, status, { headers: authHeader() })
  }
}

export default new UserService()
